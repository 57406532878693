<template>
  <div class="banner">
    <div class="banner__main">
      <picture>
        <source media="(max-width: 750px)" :srcset="mobileImg">
        <source :srcset="pcImg">
        <img :src="pcImg" alt="">
      </picture>
    </div>
    <div class="banner__tabbar">
      <div class="banner__tabbar__main">
        <div
          v-for="item in list"
          class="banner__tabbar__item"
          :class="isActive(item.val)"
          @click="handleRoutePush(item.val)"
          :key="item.val">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pcImg: {
      type: String,
      required: true
    },
    mobileImg: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      list: [
        {
          title: '政府政务',
          val: 'government-affairs'
        },
        {
          title: '金融机构',
          val: 'finance'
        },
        {
          title: '央企国企',
          val: 'operator'
        },
        {
          title: '企业用户',
          val: 'industry-institutions'
        }
      ]
    }
  },
  methods: {
    handleRoutePush (val) {
      this.$router.push(`/solution/${val}`)
    },
    isActive (val) {
      return this.$route.path.includes(val) && 'active'
    }
  }
}
</script>
<style lang="scss" scoped>
.banner__main {
  font-size: 0;
  width: 100%;

  img {
    width: 100%;
  }
}
.banner__tabbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 125px;
  background: #FFFFFF;
  box-shadow: 6px 0px 6px 1px rgba(0,0,0,0.29);

  @media screen and (max-width: 750px) {
    transform: translateY(-10px);
    border-radius: 60px 60px 0 0;
    padding: 0 50px;
    margin-top: -36px;
    box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.1);
  }
}
.banner__tabbar__main {
  display: flex;
  justify-content: space-between;
  width: 1446px;
  background: #FFFFFF;
  border-bottom: 1px solid #D5D5D5;
}
.banner__tabbar__item {
  position: relative;
  width: 266px;
  font-size: 25px;
  line-height: 1.6;
  color: #666666;
  text-align: center;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 100%;
    height: 4px;
    background-color: transparent;
  }

  &:nth-of-type(1).active::after {
    background-color: #D85139;
  }
  &:nth-of-type(2).active::after {
    background-color: rgba(55, 115, 193, 1);
  }
  &:nth-of-type(3).active::after {
    background-color: rgba(48, 162, 175, 1);
  }
  &:nth-of-type(4).active::after {
    background-color: rgba(249, 135, 8, 1);
  }

  @media screen and (max-width: 750px) {
    font-size: 42px;
    font-weight: 600;
    line-height: 2;
  }

}
</style>
