<template>
  <div class="white-item" :class="theme">
    <div v-if="title" class="white-item__title" v-html="title"></div>
    <div
      v-if="info"
      class="white-item__info"
      v-html="info"></div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      required: true
    },
    info: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.white-item {
  background: #FFFFFF;
  box-shadow: 3px 6px 9px 1px rgba(0,0,0,0.1);
  border-radius: 12px;
  padding: 35px 40px;

  &.page1 {
    .white-item__title {
      font-family: Source Han Sans CN;
      font-size: 28px;
      font-weight: 600;
      line-height: 3;
      color: rgba(216, 81, 57, 1);

      @media screen and (max-width: 750px) {
        font-size: px2rem(60);
        letter-spacing: 1px;
        line-height: 1.6;
        padding-top: 50px;
        padding-bottom: 58px;

        &::after {
          width: px2rem(275) !important;
          height: px2rem(20)  !important;
          border-radius: px2rem(10)  !important;
          background: rgba(214, 63, 39, 1);
        }
      }
    }
  }
  &.page2 {
    .white-item__title {
      font-family: Source Han Sans CN;
      font-size: 28px;
      font-weight: 600;
      line-height: 3;
      color: rgba(55, 115, 193, 1);

      @media screen and (max-width: 750px) {
        font-size: px2rem(60);
        letter-spacing: 1px;
        line-height: 1.6;
        padding-top: 50px;
        padding-bottom: 58px;

        &::after {
          width: px2rem(275) !important;
          height: px2rem(20)  !important;
          border-radius: px2rem(10)  !important;
          background: rgba(55, 115, 193, 1);
        }
      }
    }
  }
  &.page3 {
    .white-item__title {
      font-family: Source Han Sans CN;
      font-size: 28px;
      font-weight: 600;
      line-height: 3;
      color: rgba(48, 162, 175, 1);

      @media screen and (max-width: 750px) {
        font-size: px2rem(60);
        letter-spacing: 1px;
        line-height: 1.6;
        padding-top: 50px;
        padding-bottom: 58px;

        &::after {
          width: px2rem(275) !important;
          height: px2rem(20)  !important;
          border-radius: px2rem(10)  !important;
          background: rgba(48, 162, 175, 1);
        }
      }
    }
  }
  &.page4 {
    .white-item__title {
      font-family: Source Han Sans CN;
      font-size: 28px;
      font-weight: 600;
      line-height: 3;
      color: rgba(249, 135, 8, 1);

      @media screen and (max-width: 750px) {
        font-size: px2rem(60);
        letter-spacing: 1px;
        line-height: 1.6;
        padding-top: 50px;
        padding-bottom: 58px;

        &::after {
          width: px2rem(275) !important;
          height: px2rem(20)  !important;
          border-radius: px2rem(10)  !important;
          background: rgba(249, 135, 8, 1);
        }
      }
    }
  }

  @media screen and (max-width: 750px) {
    padding: 0;
    box-shadow: none;
  }
}
.white-item__title {
  font-size: 35px;
  color: #3773C1;
  text-align: center;
  background-color: #fff;

  @media screen and (max-width: 750px) {
    position: relative;
    font-size: 60px;
    font-weight: 600;
    padding-bottom: 60px;
    color: rgba(83, 83, 83, 1);
    margin-bottom: 50px;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 267px;
      height: 22px;
      transform: translateX(-50%);
      border-radius: 12px;
    }
  }
}
.white-item__info {
  font-family: Source Han Sans CN;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: justify;
  color: #666666;
  line-height: 1.8;
  margin: 0 30px 0 30px;

  @media screen and (max-width: 750px) {
    font-size: 45px;
  }
}
</style>
